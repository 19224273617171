html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    font-family: Oswald;
    word-wrap: break-word;
}

.flexbox-container {
    height: 100%;
    display: flex;            
}

.flexbox-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flexbox-combined-plot {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.flexbox-item-plot {
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
}

.flexbox-item-select-user {
  height: 5%;
}

.flexbox-item-input {
  /* flex-direction: column; */
  width: 100%;
  height: 30%;
}

.flexbox-item-plot-left {
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
}

.flexbox-item-plot-right {
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
}

.flexbox-item-legend {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 4%;
  padding-bottom: 1%;
}

input[type=text] {
  padding: 8px;
  font-family: Oswald;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
   border-radius: 0;
}

.inputText {
  width: 60%;
}

/* Button Style */
.button, .start-over-button {
  position: relative;
  background-color: #555555; 
  border: 1px solid #555555;
  color: white;
  padding: 9px;
  text-align: center;
  display: inline-block;
  transition-duration: 0.4s;
  font-family: Oswald;
}

.button:hover, .start-over-button:hover {
  background-color:white;
  color: #555555;
  border: 1px solid #555555;
}

.start-over-button {
  width: 10%;
  margin-bottom: 1%;
}

/* Plots Style */
.xAxis {
  /* font-size: 1em; */
  font-weight: bold;
  color: #333;
}

.yAxis {
  color: #333;
  font-size: 10px;
}

/* Logo Style */
.logo {
  position: absolute;
  width: 13%;
  padding: 15px;
}

.logoImage {
  width: 100%;
}

.select-user {
  margin-left: 50%;
}

.info {
  text-align: center;
  padding-top: 50px;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.second-input {
  margin-left: 15px;
}